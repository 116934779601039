<template>
  <div class="home">
    <!-- <el-button href="#answers" @click="$router.push('answers')">查看答题情况</el-button> -->
    <el-container style="height: 100%; border: 1px solid #eee">
      <el-container>
        <el-main style="overflow: none !important;">
          <div class="bigDiv">
            <el-form label-width="110px" :inline="true">
              <el-form-item label="科目名称:">
                <el-select v-model="cid" placeholder="请选择科目名称" clearable filterable>
                  <el-option v-for="item in cname" :key="item.cid" :label="item.ccourse"
                    :value="item.ccourse">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-button type="primary" icon="el-icon-plus" @click="addSubject">新增科目
              </el-button>
            </el-form>
            <el-table :data="lists" class="showTable" :stripe="true" :border="true"
              :header-cell-style="{background:'#eef1f6',color:'#606266'}">
              <el-table-column prop="c_id" label="序号" width="80">
              </el-table-column>
              <el-table-column prop="c_course" label="科目名称">
              </el-table-column>
              <!-- <el-table-column prop="bz_remark" label="任务名称">
              </el-table-column> -->
              <el-table-column prop="c_comment" label="备注">
              </el-table-column>
              <el-table-column label="操作" width="220">
                <template slot-scope="scope">
                  <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                  <el-button size="mini" type="danger"
                    @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                  <el-button size="mini" type="primary"
                    @click="handleTesk(scope.$index, scope.row)">任务</el-button>
                  <!-- <el-button size="mini" type="success"
                    @click="handleShow(scope.$index, scope.row)">查看</el-button> -->
                </template>
              </el-table-column>
              <div slot="empty">
                <p>
                  <img :src="imgUrl" />
                </p>
              </div>
            </el-table>
            <el-pagination :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
              @current-change="handleCurrentChange" :page-sizes="[100, 200, 300, 400]"
              @size-change="handleSizeChange" :total="allLength || 0" align="right">
            </el-pagination>
          </div>
        </el-main>
      </el-container>
      <!-- 新增科目弹窗 -->
      <el-dialog title="新增科目" :visible.sync="MessageBox" showCancelButton=true
        showConfirmButton=true>
        <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
          <el-form-item label="科目名称:">
            <el-input v-model="masketext" placeholder="请输入科目名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="备注 :">
            <el-input type="textarea" v-model="masketextbox" rows="6"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="MessageBox = false">取 消</el-button>
          <el-button type="primary" @click="addSubjectOk">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改弹窗 -->
      <el-dialog title="修改" :visible.sync="UpdateMessage" showCancelButton=true
        showConfirmButton=true>
        <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
          <el-form-item label="科目名称 :">
            <el-input v-model="formLabelAlign.name"></el-input>
          </el-form-item>
          <!-- <el-form-item label="任务名称 :">
            <el-input v-model="formLabelAlign.task"></el-input>
          </el-form-item> -->
          <el-form-item label="备注 :">
            <el-input type="textarea" v-model="formLabelAlign.task" rows="6"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="UpdateMessage = false">取 消</el-button>
          <el-button type="primary" @click="updateOK">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 查看详情 -->
      <el-dialog title="查看信息" :visible.sync="handleShows" showCancelButton=true
        showConfirmButton=true>
        <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
          <el-form-item label="科目名称 :">
            <span>{{formLabelAlign.name}}</span>
          </el-form-item>
          <!-- <el-form-item label="任务名称 :">
            <span>{{formLabelAlign.task}}</span>
          </el-form-item> -->
          <el-form-item label="备注 :">
            <span>{{formLabelAlign.region}}</span>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="handleShows = false">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 点击添加任务 -->
      <el-dialog :title=titleTask :visible.sync="marskAddTask" showCancelButton=true
        showConfirmButton=true>
        <el-table :data="listTask" border stripe style="width: 100%;">
          <el-table-column prop="tt_task_name" label="任务名称">
          </el-table-column>
          <el-table-column prop="tt_id" label="任务id">
          </el-table-column>
          <el-table-column prop="tt_comment" label="备注">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleUpdTask(scope.$index, scope.row)">修改</el-button>
              <el-button size="mini" type="danger"
                @click="handleTaskDelete(scope.$index, scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" icon="el-icon-plus" class="addTask" @click="AddTaskAll = true">
            添加任务
          </el-button>
          <el-button type="primary" @click="addTask">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改任务弹窗 -->
      <el-dialog title="修改" :visible.sync="UpdateTask" showCancelButton=true showConfirmButton=true>
        <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
          <el-form-item label="科目名称 :">
            <span>{{titleTask}}</span>
          </el-form-item>
          <el-form-item label="任务名称 :">
            <el-input v-model="taskName"></el-input>
          </el-form-item>
          <el-form-item label="备注 :">
            <el-input type="textarea" v-model="formLabelAlign.tt_comment" rows="6"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="UpdateTask = false">取 消</el-button>
          <el-button type="primary" @click="UpdateTaskOk">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 任务下的添加弹窗 -->
      <el-dialog title="添加任务" :visible.sync="AddTaskAll" showCancelButton=true
        showConfirmButton=true>
        <el-form :label-position="labelPosition" label-width="120px" :model="formLabelAlign">
          <el-form-item label="科目名称 :">
            <span>{{titleTask}}</span>
          </el-form-item>
          <el-form-item label="任务名称 :">
            <el-input v-model="addCnameCid.tt_task_name" @input="inputName"></el-input>
            <span v-show="showNot" style="color:orange;">已有相同任务，是否继续添加</span>
          </el-form-item>
          <el-form-item label="备注 :">
            <el-input type="textarea" rows="6" v-model="addCnameCid.bz_remark"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="AddTaskAllGo(1)">确定并继续</el-button>
          <el-button type="primary" @click="AddTaskAllGo(0)">确定并关闭</el-button>
        </span>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { parseTime } from '@/common/date.js' // 在组件中引用date.js,时间戳
import commonApi from '@/api/commonApi.js'

export default {
  data () {
    // name: 'Home'
    return {
      tableHeight: window.innerHeight - 300,
      masketext: '', // 添加框的科目名称
      masketextbox: '', // 备注
      realname: '', // 姓名
      username: '', // 学号
      cname: [], // 科目名称
      cid: '', // 选中的科目名称
      tname: [], // 根据科目名称查询任务
      sc_task: '', // 选中的任务名称
      taskName: '', // 修改任务的名称
      taskText: '', // 修改任务的备注
      indexText: 0, // 保存传送相同的key值
      ttcourseid: '', // 存放科目的id
      MessageBox: false, // 点击添加的弹窗
      showNot: false, // 提示是否加入相同的任务
      UpdateMessage: false, // 点击修改的弹窗
      UpdateTask: false, // 任务下修改的弹窗
      handleShows: false, // 查看详情弹窗
      AddTaskAll: false, // 添加任务弹窗
      inputPrice: '', // 添加任务输入框的值
      updshow: false, // 修改弹窗下是不是任务点进去的
      marskAddTask: false, // 点击任务的弹窗
      titleTask: '', // 任务头顶的标签
      labelPosition: 'right', // 弹窗的右对齐
      formLabelAlign: {
        name: '',
        task: '',
        region: '',
        cid: ''
      },
      addCnameCid: {
        km_id: '', // 新增科目
        tt_task_name: '', // 新增任务
        bz_text: ''
      },
      dstatus: [{
        name: '未答题',
        answerStatus: 0
      }, {
        name: '答题中',
        answerStatus: 1
      }, {
        name: '答题成功',
        answerStatus: 2
      }, {
        name: '答题失败',
        answerStatus: -1
      }], // 答题状态
      answerStatus: '', // 选中的答题状态
      lists: [
        // 测试数据
        // { c_name: '数据结构', sc_task: '形考任务一', bz_remark: '今晚8点见' },
        // { c_name: '数据结构', sc_task: '形考任务二', bz_remark: '今晚8点见' },
        // { c_name: 'Java程序设计', sc_task: '实践一', bz_remark: '不见不散' }
      ], // 获取到的数据
      listTask: [],
      indexPage: 1, // 当前页数，默认第一页
      pageSize: 100, // 每页显示条数
      allLength: 0, // 查询到的数据总条数
      allPageNumber: 0, // 查询到的数据总页数
      imgUrl: require('../assets/Lark20201216-174717.png') // 图片地址
    }
  },
  mounted () {
    this.allCourseName()
    this.goSearch()
  },
  methods: {
    // 点击修改出现弹窗
    handleEdit: function (index, row) {
      this.UpdateMessage = true
      this.formLabelAlign.name = row.c_course
      this.formLabelAlign.task = row.c_comment
      this.formLabelAlign.region = row.bz_remark
      this.formLabelAlign.cid = row.c_id
    },
    // 修改下的确定
    updateOK: function () {
      const data = {
        ccomment: this.formLabelAlign.task,
        ccourse: this.formLabelAlign.name,
        cid: this.formLabelAlign.cid
      }
      if (this.formLabelAlign.name !== null && this.formLabelAlign.name !== '') {
        commonApi.updateCourse(data).then((response) => {
          this.goSearch()
        }).catch((response) => {
          console.log(response)
        })
        this.UpdateMessage = false
        this.formLabelAlign.name = ''
        this.formLabelAlign.task = ''
        this.formLabelAlign.region = ''
      } else {
        this.$message({
          showClose: true,
          message: '错误！ 新增科目不能为空',
          type: 'error'
        })
      }
    },
    // 删除
    handleDelete: function (index, row) {
      const data = {
        cId: row.c_id
      }
      this.$confirm('是否删除此科目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        commonApi.deleteCourse(data).then((response) => {
          this.goSearch()
        }).catch((response) => {
          console.log(response)
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 调取渲染任务列表的接口方法
    apiListTask: function () {
      const data = {
        courseId: this.ttcourseid, // 科目的cid
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      // 调一下接口
      commonApi.showTask(data).then((response) => {
        this.listTask = response.records || []
      }).catch((response) => {
        console.log(response)
      })
    },
    // 点击任务
    handleTesk: function (index, row) {
      const data = {
        courseId: row.c_id, // 科目的cid
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      this.titleTask = row.c_course
      this.ttcourseid = row.c_id
      // 调一下接口
      commonApi.showTask(data).then((response) => {
        this.listTask = response.records || []
      }).catch((response) => {
        console.log(response)
      })
      this.marskAddTask = true
    },
    // 判断输入框的值是否已经拥有
    inputName: function (res) {
      this.inputPrice = this.addCnameCid.tt_task_name
      for (let index = 0; index < this.listTask.length; index++) {
        if (res === this.listTask[index].tt_task_name) {
          this.showNot = true
          this.indexText++
        } else {
          this.showNot = false
        }
      }
    },
    // 添加任务后点击继续还是关闭
    AddTaskAllGo: function (res) {
      // 判断提示相同的是否为true
      if (this.showNot === true) {
        this.showNot = false
      }
      const ttkeys = this.indexText.toString()
      const data = {
        ttKey: ttkeys,
        ttComment: this.addCnameCid.bz_remark,
        ttCourseId: this.ttcourseid,
        ttTaskName: this.addCnameCid.tt_task_name
      }
      if (this.addCnameCid.tt_task_name === '' || this.addCnameCid.tt_task_name === null) {
        this.$message({
          showClose: true,
          message: '添加失败！ 新增任务不能为空',
          type: 'error'
        })
      } else {
        commonApi.updateTask(data).then((response) => {
          this.apiListTask()
          this.$message({
            message: '增加任务成功',
            type: 'success'
          })
        }).catch((response) => {
          console.log(response)
        })
      }
      this.addCnameCid.tt_task_name = ''
      this.addCnameCid.bz_remark = ''
      // 如果是继续那么上传后清空值继续  否则就关闭
      if (res === 1) {
        // 调取接口后清空值
        this.addCnameCid.tt_task_name = ''
        this.addCnameCid.bz_remark = ''
      } else {
        // 调取接口后关闭
        this.AddTaskAll = false
        this.addCnameCid.tt_task_name = ''
        this.addCnameCid.bz_remark = ''
      }
      // 把这个值恢复为0
      this.indexText = 0
    },
    // 任务下的修改
    handleUpdTask: function (index, row) {
      // 任务点击的修改
      this.UpdateTask = true
      this.formLabelAlign = row
      this.taskName = this.formLabelAlign.tt_task_name
    },
    // 任务修改弹窗的确定
    UpdateTaskOk: function () {
      const data = {
        ttCourseId: this.formLabelAlign.tt_course_id,
        ttId: this.formLabelAlign.tt_id,
        ttComment: this.taskText,
        ttTaskName: this.taskName
      }
      commonApi.updateTask(data).then((response) => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.UpdateTask = false
      }).catch((response) => {
        console.log(response)
      })
    },
    // 任务下的删除
    handleTaskDelete: function (index, row) {
      const data = {
        ttId: row.tt_id
      }
      this.$confirm('是否删除该任务?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        commonApi.deleteTask(data).then((response) => {
          this.apiListTask()
        }).catch((response) => {
          console.log(response)
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 任务下的确定
    addTask: function () {
      this.marskAddTask = false
    },
    // 点击查看
    handleShow: function (index, row) {
      this.handleShows = true
      this.formLabelAlign.name = row.c_course
      // this.formLabelAlign.task = row.c_comment
      this.formLabelAlign.region = row.c_comment
    },
    // 新增科目
    addSubject () {
      this.MessageBox = true
    },
    // 新增科目的确定
    addSubjectOk () {
      const data = {
        ccomment: this.masketextbox,
        ccourse: this.masketext
      }
      // 输入的科目不为空时调取接口上传数据
      if (this.masketext !== null && this.masketext !== '') {
        // 在此处调取接口
        commonApi.addCourse(data).then((response) => {
          this.goSearch()
          this.allCourseName()
          if (response === -1) {
            this.$message({
              showClose: true,
              message: '错误！ 不可添加相同科目',
              type: 'error'
            })
          }
        }).catch((response) => {
          console.log(response)
        })
        // 清空输入值
        this.masketext = ''
        this.masketextbox = ''
        this.MessageBox = false
      } else {
        this.$message({
          showClose: true,
          message: '错误！ 科目不可为空',
          type: 'error'
        })
      }
    },
    // 查询科目名称
    allCourseName: function () {
      const AllName = { ccourse: '全部' }
      commonApi.selectCourse().then((response) => {
        this.cname = response
        this.cname.unshift(AllName)
      }).catch((response) => {
        console.log(response)
      })
    },
    // 查询数据
    goSearch: function () {
      const data = {
        current: this.indexPage, // 当前页
        size: this.pageSize // 每页数据条数
      }
      if (this.cid === '全部') this.cid = ''
      if (this.cid || this.cid === 0) data.course = this.cid
      commonApi.listCourse(data).then((response) => {
        this.lists = response.records || []
        this.allLength = Number(response.total)
        this.allPageNumber = this.allLength / this.pageSize || 1
      }).catch((response) => {
        console.log(response)
      })
    },
    // 页数切换
    handleCurrentChange (cpage) {
      this.indexPage = cpage
      this.goSearch()
    },
    // 每页条数切换
    handleSizeChange (val) {
      // 获取每页数据条数
      this.pageSize = val
      // 触发查询操作
      this.goSearch()
    },
    // 时间戳转化声明
    parseTime: parseTime
  },
  components: {
    // HelloWorld
  },
  watch: {
    // 监听科目名称下拉选框切换时发送请求获取到任务名称,并且触发查询操作
    cid (val, oldVal) {
      // 触发查询操作
      this.goSearch()
    },

    // 监听答题状态改变时触发查询操作
    answerStatus (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    },

    // 监听任务名称改变时触发查询操作
    sc_task (val, oldVal) {
      this.indexPage = 1
      this.allPageNumber = 0
      // 触发查询操作
      this.goSearch()
    }
  }
}
</script>

<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-aside {
  color: #333;
}
.addTask {
  width: 150px;
  margin-top: 50px;
}
a {
  text-decoration: none;
}
.showTable {
}
</style>
